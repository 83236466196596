<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Data &amp; Informasi
        </h5>
        <small class="text-muted">Arsitektur Domain Data dan Informasi SPBE.</small>
      </b-col>
      <b-col
        v-if="dataData.is_verified"
        cols="12"
        class="mb-1"
      >
        <b-alert
          :variant="(dataData.is_verified === 1) ? 'success' : 'warning'"
          show
        >
          <h4
            v-if="dataData.is_verified !== 1"
            class="alert-heading"
          >
            Catatan Perbaikan
          </h4>
          <div
            v-if="dataData.is_verified !== 1 && dataData.keterangan !== ''"
            class="alert-body"
          >
            <span>{{ dataData.keterangan }}</span>
          </div>
          <div
            v-if="dataData.is_verified === 1"
            class="alert-body"
          >
            <span>Terverifikasi</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="!dataData.nama"
        md="12"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Data dan Informasi sesuai dengan Renja'"
          label-for="vi-master_data"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Data dan Informasi atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_data"
              v-model="master_data"
              required
              name="master_data"
              :options="referenceData.data_informasi_list"
              placeholder="Pilih Nama Data dan Informasi atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Data Pokok"
          label-for="vi-data_basic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Pokok"
            rules="required"
          >
            <v-select
              id="vi-data_basic_id"
              v-model="data_basic_id"
              required
              name="data_basic_id"
              :options="referenceData.ref_data_pokok"
              placeholder="Data Pokok"
              label="nama"
              @input="getDataTematik()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Data Tematik"
          label-for="vi-data_tematic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Tematik"
            rules="required"
          >
            <v-select
              id="vi-data_tematic_id"
              v-model="data_tematic_id"
              required
              name="data_tematic_id"
              :options="daftarDataTematik"
              placeholder="Data Tematik"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'"
          label="Nama Data"
          label-for="vi-nama"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Data"
            rules="required"
          >
            <b-form-input
              id="vi-nama"
              v-model="dataData.nama"
              required
              name="nama"
              placeholder="Nama Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
          label="Interoperabilitas Data"
          label-for="vi-data_interoperability_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Interoperabilitas Data"
            rules="required"
          >
            <v-select
              id="vi-data_interoperability_id"
              v-model="data_interoperability_id"
              required
              name="data_interoperability_id"
              :options="daftarDataInteroperability"
              placeholder="Interoperabilitas Data"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
          label="Sifat Data"
          label-for="vi-data_status_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sifat Data"
            rules="required"
          >
            <v-select
              id="vi-data_status_id"
              v-model="data_status_id"
              required
              name="data_status_id"
              :options="referenceData.ref_sifat_data"
              placeholder="Sifat Data"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari pemanfaatan data.'"
          label="Tujuan Data"
          label-for="vi-tujuan"
        >
          <validation-provider
            #default="{ errors }"
            name="Tujuan Data"
            rules="required"
          >
            <b-form-textarea
              id="vi-tujuan"
              v-model="dataData.tujuan"
              rows="4"
              required
              name="tujuan"
              placeholder="Tujuan Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan tanggal mulai berlakunya data.'"
          label="Awal Validitas Data"
          label-for="vi-valid_start"
        >
          <validation-provider
            #default="{ errors }"
            name="Awal Validitas Data"
            rules="required"
          >
            <b-form-datepicker
              id="vi-valid_start"
              v-model="dataData.valid_start"
              :max="dataData.valid_end"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              size="sm"
              required
              name="valid_start"
              placeholder="Awal Validitas Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan tanggal akhir berlakunya data.'"
          label="Akhir Validitas Data"
          label-for="vi-valid_end"
        >
          <validation-provider
            #default="{ errors }"
            name="Akhir Validitas Data"
            rules="required"
          >
            <b-form-datepicker
              id="vi-valid_end"
              v-model="dataData.valid_end"
              :min="dataData.valid_start"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              size="sm"
              required
              name="valid_end"
              placeholder="Akhir Validitas Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penanggung jawab dari data.'"
          label="Penanggung Jawab"
          label-for="vi-pic_name"
        >
          <validation-provider
            #default="{ errors }"
            name="Penanggung Jawab"
            rules="required"
          >
            <b-form-input
              id="vi-pic_name"
              v-model="dataData.pic_name"
              required
              name="pic_name"
              placeholder="Penanggung Jawab"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'"
          label="Uraian Data"
          label-for="vi-deskripsi"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Data"
            rules="required"
          >
            <b-form-textarea
              id="vi-deskripsi"
              v-model="dataData.deskripsi"
              rows="4"
              required
              name="deskripsi"
              placeholder="Uraian Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BFormInput, BRow, BCol, BFormDatepicker, BFormGroup, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      dataData: { is_verified: false },
      clearanceStatus: null,
      referenceData: {
        data_informasi_list: [],
        ref_data_pokok: [],
        ref_data_tematik: [],
        ref_sifat_data: [],
      },
      data_utama_id: '',
      master_data: '',
      data_basic_id: '',
      data_tematic_id: '',
      data_interoperability_id: '',
      data_status_id: '',
      daftarDataInteroperability: [
        { value: '0', nama: 'Tidak' },
        { value: '1', nama: 'Ya' },
      ],
      daftarDataTematik: [],
      daftarMasterDataInformasi: { data_pokok_id: 'new', label: 'Tambah Data dan Informasi Baru' },
    }
  },
  watch: {
    actionStep() {
      this.saveInformasi()
    },
  },
  created() {
    this.data_utama_id = Number(this.$route.params.id)
  },
  mounted() {
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/data-informasi/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data

            if (res.data.data.data_informasi_list) {
              const pics = [this.daftarMasterDataInformasi]
              res.data.data.data_informasi_list.map(value => {
                const pic = value
                pic.label = `${value.nama} / ${value.data_pokok} / ${value.data_tematik}`
                pics.push(pic)
                return true
              })
              this.referenceData.data_informasi_list = pics
            }
            this.setReference()
          }
        })
    },
    setSelected() {
      if (this.master_data.data_informasi_id !== 'new') {
        this.dataData = this.master_data

        this.nama = this.dataData.nama
        this.tujuan = this.dataData.tujuan
        this.valid_start = this.dataData.valid_start
        this.valid_end = this.dataData.valid_end
        this.pic_name = this.dataData.pic_name
        this.deskripsi = this.dataData.deskripsi
        this.walidata = this.dataData.walidata

        this.data_basic_id = {
          data_pokok_id: this.dataData.data_pokok_id,
          nama: this.dataData.data_pokok,
        }
        this.getDataTematik()

        if (this.dataData.data_tematik_id) {
          this.data_tematic_id = {
            data_tematik_id: this.dataData.data_tematik_id,
            nama: this.dataData.data_tematik,
          }
        }

        this.data_status_id = {
          sifat_data_id: this.dataData.sifat_data_id,
          nama: this.dataData.sifat_data,
        }

        this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
      }
    },
    setReference() {
      this.$http.get('/clearance/data-informasi', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataData = res.data.data

            this.data_basic_id = {
              data_pokok_id: this.dataData.data_pokok_id,
              nama: this.dataData.data_pokok,
            }
            this.getDataTematik()
            this.data_tematic_id = {
              data_tematik_id: this.dataData.data_tematik_id,
              nama: this.dataData.data_tematik,
            }
            this.data_status_id = {
              sifat_data_id: this.dataData.sifat_data_id,
              nama: this.dataData.sifat_data,
            }
            this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
          }
        })
    },
    getDataTematik() {
      this.daftarDataTematik.splice(0)
      this.data_tematic_id = ''

      const filterVal = this.data_basic_id.data_pokok_id
      const refData = this.referenceData.ref_data_tematik
      refData.map(contact => {
        if (contact.data_pokok_id === filterVal) {
          this.daftarDataTematik.push(contact)
        }
        return true
      })
    },
    saveInformasi() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.data_utama_id,
        instansi_id: this.dataData.instansi_id,
        data_pokok_id: this.data_basic_id ? this.data_basic_id.data_pokok_id : 1,
        data_tematik_id: this.data_tematic_id ? this.data_tematic_id.data_tematik_id : 1,
        nama: this.dataData.nama,
        interoperability_id: this.data_interoperability_id.value,
        sifat_data_id: this.data_status_id.sifat_data_id,
        tujuan: this.dataData.tujuan,
        valid_start: this.dataData.valid_start,
        valid_end: this.dataData.valid_end,
        pic_name: this.dataData.pic_name,
        deskripsi: this.dataData.deskripsi,
      }
      if (this.dataData.data_informasi_id) {
        metaUtama.data_informasi_id = this.dataData.data_informasi_id
      }
      this.$http.post('/clearance/data-informasi', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
