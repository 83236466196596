var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Metadata Utama ")])]),(_vm.saktiLoad)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Memuat data "),_c('b',[_vm._v("Sakti")]),_vm._v(", mohon menunggu")])])])],1):_vm._e(),(_vm.saktiFailed)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found with Sakti ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Konektivitas dengan "),_c('u',[_vm._v("Sakti")]),_vm._v(" gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi "),_c('u',[_vm._v("Admin")])])])])],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Unit Kerja sesuai dengan Renja'),expression:"'Nama Unit Kerja sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Kerja","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Unit Kerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-unit_kerja","required":"","name":"unit_kerja","options":_vm.saktiUnit,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetKegiatan()}},model:{value:(_vm.unit_kerja),callback:function ($$v) {_vm.unit_kerja=$$v},expression:"unit_kerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1209550171)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Unit Kerja sesuai dengan Renja'),expression:"'Nama Unit Kerja sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Unit Kerja","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Nama Unit Kerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-nama_unit_kerja","required":"","name":"nama_unit_kerja","placeholder":"Unit Kerja (Lengkap Kode dan Nama)","autofocus":(_vm.fokusRo === 'unit') ? true : false},model:{value:(_vm.s_unit_kerja),callback:function ($$v) {_vm.s_unit_kerja=$$v},expression:"s_unit_kerja"}}),_c('b-input-group-append',[(_vm.s_unit_kerja)?_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.unitReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1):_vm._e()],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Kegiatan sesuai dengan Renja'),expression:"'Nama Kegiatan sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Kegiatan","label-for":"vi-nama_kegiatan"}},[_c('validation-provider',{attrs:{"name":"Nama Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-nama_kegiatan","required":"","name":"nama_kegiatan","options":_vm.saktiKegiatan,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetKro()}},model:{value:(_vm.nama_kegiatan),callback:function ($$v) {_vm.nama_kegiatan=$$v},expression:"nama_kegiatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1859775619)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Kegiatan sesuai dengan Renja'),expression:"'Nama Kegiatan sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Kegiatan","label-for":"vi-nama_kegiatan"}},[_c('validation-provider',{attrs:{"name":"Nama Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-nama_kegiatan","required":"","name":"nama_kegiatan","placeholder":"Nama Kegiatan (Lengkap Kode dan Nama)","autofocus":(_vm.fokusRo === 'giat') ? true : false},model:{value:(_vm.s_nama_kegiatan),callback:function ($$v) {_vm.s_nama_kegiatan=$$v},expression:"s_nama_kegiatan"}}),_c('b-input-group-append',[(_vm.s_nama_kegiatan)?_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.namaReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1):_vm._e()],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Penanggung Jawab sesuai dengan Renja'),expression:"'Nama Penanggung Jawab sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Penanggung Jawab","label-for":"vi-pic"}},[_c('b-form-input',{attrs:{"hidden":"","name":"pic_id"},model:{value:(_vm.dataUtama.pic_id),callback:function ($$v) {_vm.$set(_vm.dataUtama, "pic_id", $$v)},expression:"dataUtama.pic_id"}}),_c('validation-provider',{attrs:{"name":"Nama Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-pic_id","required":"","name":"pic_id","options":_vm.referenceData.ref_pic,"label":"label","placeholder":"Pilih Salah Satu atau Tambah Baru"},on:{"input":function($event){return _vm.setContact()}},model:{value:(_vm.penanggungJawab),callback:function ($$v) {_vm.penanggungJawab=$$v},expression:"penanggungJawab"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nomor Kontak Penanggung Jawab sesuai dengan Renja'),expression:"'Nomor Kontak Penanggung Jawab sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nomor Penanggung Jawab","label-for":"vi-pic_number"}},[_c('validation-provider',{attrs:{"name":"Nomor Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-pic_number","required":"","name":"pic_number","placeholder":"Nomor Penanggung Jawab"},model:{value:(_vm.dataUtama.pic_number),callback:function ($$v) {_vm.$set(_vm.dataUtama, "pic_number", $$v)},expression:"dataUtama.pic_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Klasifikasi Rincian Output Program sesuai dengan Renja'),expression:"'Klasifikasi Rincian Output Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Klasifikasi Rincian Output (KRO)","label-for":"vi-kode_model_referensi"}},[_c('validation-provider',{attrs:{"name":"Klasifikasi Rincian Output (KRO)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kode_model_referensi","required":"","name":"kode_model_referensi","options":_vm.saktiKro,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetRo()}},model:{value:(_vm.kode_model_referensi),callback:function ($$v) {_vm.kode_model_referensi=$$v},expression:"kode_model_referensi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1141779679)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Klasifikasi Rincian Output Program sesuai dengan Renja'),expression:"'Klasifikasi Rincian Output Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Klasifikasi Rincian Output (KRO)","label-for":"vi-kode_model_referensi"}},[_c('validation-provider',{attrs:{"name":"Nama Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-kode_model_referensi","required":"","name":"kode_model_referensi","placeholder":"Klasifikasi Rincian Output (KRO) Lengkap Kode dan Nama","autofocus":(_vm.fokusRo === 'kro') ? true : false},model:{value:(_vm.s_kode_model_referensi),callback:function ($$v) {_vm.s_kode_model_referensi=$$v},expression:"s_kode_model_referensi"}}),_c('b-input-group-append',[(_vm.s_kode_model_referensi)?_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.kroReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1):_vm._e()],1)],1),_c('small',{staticClass:"text-info"},[_vm._v("Pastikan penulisan KRO telah sesuai dan tepat."),_c('br')]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Rincian Output Program sesuai dengan Renja'),expression:"'Rincian Output Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Rincian Output (RO)","label-for":"vi-rincian_output"}},[_c('validation-provider',{attrs:{"name":"Rincian Output (RO)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-rincian_output","required":"","name":"rincian_output","options":_vm.saktiRo,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetItem()}},model:{value:(_vm.rincian_output),callback:function ($$v) {_vm.rincian_output=$$v},expression:"rincian_output"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2371695185)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Rincian Output Program sesuai dengan Renja'),expression:"'Rincian Output Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Rincian Output (RO)","label-for":"vi-rincian_output"}},[_c('validation-provider',{attrs:{"name":"Nama Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-rincian_output","required":"","name":"rincian_output","placeholder":"Rincian Output (RO) Lengkap Kode dan Nama"},model:{value:(_vm.dataUtama.rincian_output),callback:function ($$v) {_vm.$set(_vm.dataUtama, "rincian_output", $$v)},expression:"dataUtama.rincian_output"}}),_c('small',{staticClass:"text-info"},[_vm._v("Pastikan penulisan RO telah sesuai dan tepat."),_c('br')]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tahun Anggaran Program sesuai dengan Renja'),expression:"'Tahun Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Tahun Anggaran","label-for":"vi-tahun_anggaran"}},[_c('validation-provider',{attrs:{"name":"Tahun Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-tahun_anggaran","required":"","name":"tahun_anggaran","placeholder":"Tahun Anggaran"},model:{value:(_vm.dataUtama.tahun_anggaran),callback:function ($$v) {_vm.$set(_vm.dataUtama, "tahun_anggaran", $$v)},expression:"dataUtama.tahun_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Total Anggaran Program sesuai dengan Renja'),expression:"'Total Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Total Anggaran " + ((_vm.dataUtama.total_anggaran !== '') ? ': Rp. ' + Number(_vm.dataUtama.total_anggaran).toLocaleString() : '')),"label-for":"vi-total_anggaran"}},[_c('validation-provider',{attrs:{"name":"Total Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-total_anggaran","required":"","name":"total_anggaran","placeholder":"Total Anggaran","disabled":(_vm.saktiFailed === true) ? false : true},model:{value:(_vm.dataUtama.total_anggaran),callback:function ($$v) {_vm.$set(_vm.dataUtama, "total_anggaran", $$v)},expression:"dataUtama.total_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1087745513)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Total Anggaran Program sesuai dengan Renja'),expression:"'Total Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Total Anggaran " + ((_vm.dataUtama.total_anggaran !== '') ? ': Rp. ' + Number(_vm.dataUtama.total_anggaran).toLocaleString() : '')),"label-for":"vi-total_anggaran"}},[_c('validation-provider',{attrs:{"name":"Total Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-total_anggaran","type":"number","required":"","name":"total_anggaran","placeholder":"Total Anggaran"},model:{value:(_vm.dataUtama.total_anggaran),callback:function ($$v) {_vm.$set(_vm.dataUtama, "total_anggaran", $$v)},expression:"dataUtama.total_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Kode Mata Anggaran Program sesuai dengan Renja'),expression:"'Kode Mata Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kode Mata Anggaran","label-for":"vi-kode_anggaran"}},[_c('validation-provider',{attrs:{"name":"Kode Mata Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-kode_anggaran","required":"","name":"kode_anggaran","placeholder":"Kode Mata Anggaran","disabled":(_vm.saktiFailed === true) ? false : true},model:{value:(_vm.dataUtama.kode_anggaran),callback:function ($$v) {_vm.$set(_vm.dataUtama, "kode_anggaran", $$v)},expression:"dataUtama.kode_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,437363280)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Kode Mata Anggaran Program sesuai dengan Renja'),expression:"'Kode Mata Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kode Mata Anggaran","label-for":"vi-kode_anggaran"}},[_c('validation-provider',{attrs:{"name":"Kode Mata Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-kode_anggaran","required":"","name":"kode_anggaran","placeholder":"Kode Mata Anggaran"},model:{value:(_vm.dataUtama.kode_anggaran),callback:function ($$v) {_vm.$set(_vm.dataUtama, "kode_anggaran", $$v)},expression:"dataUtama.kode_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('small',{staticClass:"text-info"},[_vm._v("contoh : [Kode Dept].[Kode Unit].[Kode Kegiatan].[Kode KRO].[Kode RO]")])],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[(_vm.useSakti)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Apakah Program Merupakan Prioritas Nasional?'),expression:"'Apakah Program Merupakan Prioritas Nasional?'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Prioritas Nasional","label-for":"vi-nasional_priority"}},[_c('validation-provider',{attrs:{"name":"Prioritas Nasional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-nasional_priority","required":"","name":"nasional_priority","options":_vm.daftarNationalPriority,"placeholder":"Pilih Salah Satu","label":"text","disabled":(_vm.saktiFailed === true) ? false : true},model:{value:(_vm.nationalPriority),callback:function ($$v) {_vm.nationalPriority=$$v},expression:"nationalPriority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3349130430)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Apakah Program Merupakan Prioritas Nasional?'),expression:"'Apakah Program Merupakan Prioritas Nasional?'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Prioritas Nasional","label-for":"vi-prioritas_nasional"}},[_c('validation-provider',{attrs:{"name":"Prioritas Nasional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-prioritas_nasional","required":"","name":"prioritas_nasional","options":_vm.daftarNationalPriority,"placeholder":"Pilih Salah Satu","label":"text"},model:{value:(_vm.nationalPriority),callback:function ($$v) {_vm.nationalPriority=$$v},expression:"nationalPriority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis Belanja Program sesuai dengan Renja'),expression:"'Jenis Belanja Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Belanja","label-for":"vi-master_jenis_belanja_id"}},[_c('validation-provider',{attrs:{"name":"Jenis Belanja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_jenis_belanja_id","required":"","name":"master_jenis_belanja_id","options":_vm.referenceData.ref_jenis_belanja,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.master_jenis_belanja_id),callback:function ($$v) {_vm.master_jenis_belanja_id=$$v},expression:"master_jenis_belanja_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis Pengadaan Program sesuai dengan Renja'),expression:"'Jenis Pengadaan Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Pengadaan","label-for":"vi-master_jenis_pengadaan_id"}},[_c('validation-provider',{attrs:{"name":"Jenis Pengadaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_jenis_pengadaan_id","required":"","name":"master_jenis_pengadaan_id","options":_vm.referenceData.ref_jenis_pengadaan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.master_jenis_pengadaan_id),callback:function ($$v) {_vm.master_jenis_pengadaan_id=$$v},expression:"master_jenis_pengadaan_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Sumber Dana Program sesuai dengan Renja'),expression:"'Sumber Dana Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sumber Dana","label-for":"vi-master_sumber_dana_id"}},[_c('validation-provider',{attrs:{"name":"Sumber Dana","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_sumber_dana_id","required":"","name":"master_sumber_dana_id","options":_vm.referenceData.ref_sumber_dana,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.master_sumber_dana_id),callback:function ($$v) {_vm.master_sumber_dana_id=$$v},expression:"master_sumber_dana_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }