<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Proses Bisnis
        </h5>
        <small class="text-muted">Arsitektur Domain Proses Bisnis SPBE.</small>
      </b-col>
      <b-col
        v-if="saktiFailed"
        cols="12"
        class="mb-1"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            <span>Konektivitas dengan <u>Sakti</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi <u>Admin</u></span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="bisnisData.is_verified"
        cols="12"
        class="mb-1"
      >
        <b-alert
          :variant="(bisnisData.is_verified === 1) ? 'success' : 'warning'"
          show
        >
          <h4
            v-if="bisnisData.is_verified !== 1"
            class="alert-heading"
          >
            Catatan Perbaikan
          </h4>
          <div
            v-if="bisnisData.is_verified !== 1 && bisnisData.keterangan !== ''"
            class="alert-body"
          >
            <span>{{ bisnisData.keterangan }}</span>
          </div>
          <div
            v-if="bisnisData.is_verified === 1"
            class="alert-body"
          >
            <span>Terverifikasi</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="programUpdate"
        cols="12"
        class="mb-1"
      >
        <b-alert
          variant="info"
          show
        >
          <h4 class="alert-heading">
            Perhatian
          </h4>
          <div class="alert-body">
            <span>Nama Program disesuaikan dengan data <u>Sakti</u>, periksa kembali isian Domain Proses Bisnis Anda.</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="!bisnisData.nama_urusan"
        md="12"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Proses Bisnis sesuai dengan Renja'"
          label-for="vi-master_bisnis"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Urusan dan Nama Program atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_bisnis"
              v-model="master_bisnis"
              required
              name="master_bisnis"
              :options="referenceData.business_data_list"
              placeholder="Pilih Nama Urusan dan Nama Program atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Program sesuai dengan Renja'"
          label="Nama Program"
          label-for="vi-nama_program"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Program"
            rules="required"
          >
            <b-form-input
              id="vi-nama_program"
              v-model="bisnisData.nama_program"
              required
              name="nama_program"
              placeholder="Nama Program"
              :disabled="(saktiProgram !== '') ? true : false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Sektor Pemerintahan"
          label-for="vi-gov_sector_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sektor Pemerintahan"
            rules="required"
          >
            <v-select
              id="vi-gov_sector_id"
              v-model="gov_sector_id"
              required
              name="gov_sector_id"
              :options="referenceData.ref_sektor_pemerintahan"
              placeholder="Sektor Pemerintahan"
              label="nama"
              @input="getDaftarUrusan()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Urusan Pemerintahan"
          label-for="vi-gov_affair_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Urusan Pemerintahan"
            rules="required"
          >
            <v-select
              id="vi-gov_affair_id"
              ref="gov_affair_id"
              v-model="gov_affair_id"
              required
              name="gov_affair_id"
              :options="daftarUrusan"
              placeholder="Urusan Pemerintahan"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'"
          label="Nama Bisnis / Urusan"
          label-for="vi-nama_urusan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Bisnis / Urusan"
            rules="required"
          >
            <b-form-input
              id="vi-nama_urusan"
              v-model="bisnisData.nama_urusan"
              required
              name="nama_urusan"
              placeholder="Nama Bisnis / Urusan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Instansi yang mengajukan'"
          label="Instansi"
          label-for="vi-instansi_id"
        >
          <b-form-input
            v-model="bisnisData.instansi_id"
            hidden
            required
            name="instansi_id"
            placeholder="Instansi"
          />
          <b-form-input
            v-model="bisnisData.instansi"
            disabled
            placeholder="Instansi"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan uraian dari urusan-urusan pemerintahan yang diselenggarakan yang mengacu pada tugas unit kerja/perangkat daerah.'"
          label="Uraian Bisnis / Urusan"
          label-for="vi-deskripsi_urusan"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Urusan"
            rules="required"
          >
            <b-form-textarea
              id="vi-deskripsi_urusan"
              v-model="bisnisData.deskripsi_urusan"
              rows="4"
              required
              name="deskripsi_urusan"
              placeholder="Uraian Bisnis / Urusan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BFormInput, BRow, BCol, BFormGroup, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    sakti: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      data_utama_id: '',
      proses_bisnis_id: '',
      referenceData: {
        business_data_list: [],
        ref_sektor_pemerintahan: [],
        ref_urusan_pemerintahan: [],
      },
      master_bisnis: '',
      bisnisData: {
        nama_program: '',
        nama_urusan: '',
        deskripsi_urusan: '',
        is_verified: false,
      },
      gov_sector_id: '',
      gov_affair_id: '',
      daftarUrusan: [],
      daftarMasterBisnis: { proses_bisnis_id: 'new', label: 'Tambah Nama Bisnis / Nama Urusan Baru' },
      programUpdate: false,
      saktiFailed: false,
      saktiFailedMsg: '',
      saktiToken: '',
      saktiProgram: '',
    }
  },
  watch: {
    actionStep() {
      this.saveBisnis()
    },
    sakti() {
      this.splpSaktiGetToken()
    },
  },
  created() {
    this.data_utama_id = Number(this.$route.params.id)
  },
  mounted() {
    this.getReference()

    this.bisnisData.instansi_id = this.userData.instansi_id
    this.bisnisData.instansi = this.userData.instansi_name
  },
  methods: {
    splpSaktiGetToken() {
      if (Object.keys(this.sakti).length !== 0) {
        document.getElementById('loading-bg').style.display = 'block'
        this.$http.get(`/sakti/token?token=${localStorage.getItem('userToken')}`)
          .then(res => {
            if (res.data.status === 'success') {
              this.saktiToken = res.data.data.access_token
              this.saktiGetProgram()
            } else {
              this.saktiFailed = true
              document.getElementById('loading-bg').style.display = 'none'
            }
          })
          .catch(error => {
            this.saktiFailedMsg = error
            this.saktiFailed = true
            document.getElementById('loading-bg').style.display = 'none'
          })
      }
    },
    saktiGetProgram() {
      if (Object.keys(this.sakti).length !== 0) {
        document.getElementById('loading-bg').style.display = 'block'

        const params = {
          year: this.coreData.tahun_anggaran,
          table: 'program',
          kddept: this.userData.instansi_kddept,
          kdunit: this.sakti.kdunit,
          kdgiat: this.sakti.kdgiat,
          kdprogram: this.sakti.kdprogram,
        }
        this.$splp.get('/ega', {
          headers: {
            Authorization: `Bearer ${this.saktiToken}`,
          },
          params,
        })
          .then(res => {
            if (res.data.success === true) {
              res.data.data.map(value => {
                this.saktiProgram = value
                return true
              })
              if (this.saktiProgram !== '') {
                this.nama_program = `${this.saktiProgram.kddept}.${this.saktiProgram.kdprogram}. ${this.saktiProgram.nmprogram}`
              }
              this.getReference()
              document.getElementById('loading-bg').style.display = 'none'
            } else {
              this.saktiFailed = true
              document.getElementById('loading-bg').style.display = 'none'
            }
          })
          .catch(error => {
            this.saktiFailedMsg = error
            this.saktiFailed = true
            document.getElementById('loading-bg').style.display = 'none'
          })
      }
    },
    getReference() {
      this.$http.get('/clearance/business-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.business_data_list) {
              const pics = [this.daftarMasterBisnis]
              res.data.data.business_data_list.map(value => {
                const pic = value
                pic.label = `${value.nama_urusan} / ${value.nama_program} / ${value.sektor_pemerintahan} / ${value.urusan_pemerintahan}`
                pics.push(pic)
                return true
              })
              this.referenceData.business_data_list = pics
            }
            this.master_bisnis = this.daftarMasterBisnis
            this.setReference()
          }
        })
    },
    setSelected() {
      if (this.master_bisnis.proses_bisnis_id !== 'new') {
        this.bisnisData = this.master_bisnis

        this.nama_program = this.bisnisData.nama_program
        this.nama_urusan = this.bisnisData.nama_urusan
        this.deskripsi_urusan = this.bisnisData.deskripsi_urusan

        this.gov_sector_id = {
          sektor_pemerintahan_id: this.bisnisData.sektor_pemerintahan_id,
          nama: this.bisnisData.sektor_pemerintahan,
        }
        this.getDaftarUrusan()

        if (this.bisnisData.urusan_pemerintahan_id) {
          this.gov_affair_id = {
            urusan_pemerintahan_id: this.bisnisData.urusan_pemerintahan_id,
            nama: this.bisnisData.urusan_pemerintahan,
          }
        }
      }
    },
    setReference() {
      this.$http.get('/clearance/business-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data.length !== 0) {
            [this.bisnisData] = res.data.data
            if (this.saktiProgram !== '') {
              this.bisnisData.nama_program = `${this.saktiProgram.kddept}.${this.saktiProgram.kdprogram}. ${this.saktiProgram.nmprogram}`
              this.programUpdate = true
            }

            this.gov_sector_id = {
              sektor_pemerintahan_id: this.bisnisData.sektor_pemerintahan_id,
              nama: this.bisnisData.sektor_pemerintahan,
            }
            this.getDaftarUrusan()
            this.gov_affair_id = {
              urusan_pemerintahan_id: this.bisnisData.urusan_pemerintahan_id,
              nama: this.bisnisData.urusan_pemerintahan,
            }
          }

          if (this.saktiProgram !== '') {
            this.bisnisData.nama_program = `${this.saktiProgram.kddept}.${this.saktiProgram.kdprogram}. ${this.saktiProgram.nmprogram}`
            this.programUpdate = true
          }
        })
    },
    getDaftarUrusan() {
      this.daftarUrusan.splice(0)
      this.gov_affair_id = ''

      const filterVal = this.gov_sector_id.sektor_pemerintahan_id
      const refUrusan = this.referenceData.ref_urusan_pemerintahan
      refUrusan.map(contact => {
        if (contact.sektor_pemerintahan_id === filterVal) {
          this.daftarUrusan.push(contact)
        }
        return true
      })
    },
    saveBisnis() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.data_utama_id,
        nama_program: this.bisnisData.nama_program,
        sektor_pemerintahan_id: this.gov_sector_id.sektor_pemerintahan_id,
        urusan_pemerintahan_id: this.gov_affair_id.urusan_pemerintahan_id,
        nama_urusan: this.bisnisData.nama_urusan,
        instansi_id: this.bisnisData.instansi_id,
        deskripsi_urusan: this.bisnisData.deskripsi_urusan,
      }
      if (this.bisnisData.proses_bisnis_id) {
        metaUtama.proses_bisnis_id = this.bisnisData.proses_bisnis_id
      }
      this.$http.post('/clearance/business-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
            sakti: this.saktiProgram,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
