var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Data & Informasi ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Arsitektur Domain Data dan Informasi SPBE.")])]),(_vm.dataData.is_verified)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":(_vm.dataData.is_verified === 1) ? 'success' : 'warning',"show":""}},[(_vm.dataData.is_verified !== 1)?_c('h4',{staticClass:"alert-heading"},[_vm._v(" Catatan Perbaikan ")]):_vm._e(),(_vm.dataData.is_verified !== 1 && _vm.dataData.keterangan !== '')?_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.dataData.keterangan))])]):_vm._e(),(_vm.dataData.is_verified === 1)?_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Terverifikasi")])]):_vm._e()])],1):_vm._e(),(!_vm.dataData.nama)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Arsitektur Domain Data dan Informasi sesuai dengan Renja'),expression:"'Arsitektur Domain Data dan Informasi sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label-for":"vi-master_data"}},[_c('validation-provider',{attrs:{"name":"Pilih Nama Data dan Informasi atau Tambah Baru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_data","required":"","name":"master_data","options":_vm.referenceData.data_informasi_list,"placeholder":"Pilih Nama Data dan Informasi atau Tambah Baru","label":"label"},on:{"input":function($event){return _vm.setSelected()}},model:{value:(_vm.master_data),callback:function ($$v) {_vm.master_data=$$v},expression:"master_data"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1614171818)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Pokok","label-for":"vi-data_basic_id"}},[_c('validation-provider',{attrs:{"name":"Data Pokok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_basic_id","required":"","name":"data_basic_id","options":_vm.referenceData.ref_data_pokok,"placeholder":"Data Pokok","label":"nama"},on:{"input":function($event){return _vm.getDataTematik()}},model:{value:(_vm.data_basic_id),callback:function ($$v) {_vm.data_basic_id=$$v},expression:"data_basic_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Tematik","label-for":"vi-data_tematic_id"}},[_c('validation-provider',{attrs:{"name":"Data Tematik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_tematic_id","required":"","name":"data_tematic_id","options":_vm.daftarDataTematik,"placeholder":"Data Tematik","label":"nama"},model:{value:(_vm.data_tematic_id),callback:function ($$v) {_vm.data_tematic_id=$$v},expression:"data_tematic_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'),expression:"'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Data","label-for":"vi-nama"}},[_c('validation-provider',{attrs:{"name":"Nama Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama","required":"","name":"nama","placeholder":"Nama Data"},model:{value:(_vm.dataData.nama),callback:function ($$v) {_vm.$set(_vm.dataData, "nama", $$v)},expression:"dataData.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'),expression:"'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Interoperabilitas Data","label-for":"vi-data_interoperability_id"}},[_c('validation-provider',{attrs:{"name":"Interoperabilitas Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_interoperability_id","required":"","name":"data_interoperability_id","options":_vm.daftarDataInteroperability,"placeholder":"Interoperabilitas Data","label":"nama"},model:{value:(_vm.data_interoperability_id),callback:function ($$v) {_vm.data_interoperability_id=$$v},expression:"data_interoperability_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan klasifikasi data berdasarkan bentuk datanya.'),expression:"'Merupakan klasifikasi data berdasarkan bentuk datanya.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sifat Data","label-for":"vi-data_status_id"}},[_c('validation-provider',{attrs:{"name":"Sifat Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_status_id","required":"","name":"data_status_id","options":_vm.referenceData.ref_sifat_data,"placeholder":"Sifat Data","label":"nama"},model:{value:(_vm.data_status_id),callback:function ($$v) {_vm.data_status_id=$$v},expression:"data_status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan tujuan dari pemanfaatan data.'),expression:"'Merupakan penjelasan tujuan dari pemanfaatan data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Tujuan Data","label-for":"vi-tujuan"}},[_c('validation-provider',{attrs:{"name":"Tujuan Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-tujuan","rows":"4","required":"","name":"tujuan","placeholder":"Tujuan Data"},model:{value:(_vm.dataData.tujuan),callback:function ($$v) {_vm.$set(_vm.dataData, "tujuan", $$v)},expression:"dataData.tujuan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tanggal mulai berlakunya data.'),expression:"'Merupakan tanggal mulai berlakunya data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Awal Validitas Data","label-for":"vi-valid_start"}},[_c('validation-provider',{attrs:{"name":"Awal Validitas Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"vi-valid_start","max":_vm.dataData.valid_end,"date-format-options":{ year: 'numeric', month: 'short', day: 'numeric' },"size":"sm","required":"","name":"valid_start","placeholder":"Awal Validitas Data"},model:{value:(_vm.dataData.valid_start),callback:function ($$v) {_vm.$set(_vm.dataData, "valid_start", $$v)},expression:"dataData.valid_start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tanggal akhir berlakunya data.'),expression:"'Merupakan tanggal akhir berlakunya data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Akhir Validitas Data","label-for":"vi-valid_end"}},[_c('validation-provider',{attrs:{"name":"Akhir Validitas Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"vi-valid_end","min":_vm.dataData.valid_start,"date-format-options":{ year: 'numeric', month: 'short', day: 'numeric' },"size":"sm","required":"","name":"valid_end","placeholder":"Akhir Validitas Data"},model:{value:(_vm.dataData.valid_end),callback:function ($$v) {_vm.$set(_vm.dataData, "valid_end", $$v)},expression:"dataData.valid_end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penanggung jawab dari data.'),expression:"'Merupakan penanggung jawab dari data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Penanggung Jawab","label-for":"vi-pic_name"}},[_c('validation-provider',{attrs:{"name":"Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-pic_name","required":"","name":"pic_name","placeholder":"Penanggung Jawab"},model:{value:(_vm.dataData.pic_name),callback:function ($$v) {_vm.$set(_vm.dataData, "pic_name", $$v)},expression:"dataData.pic_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'),expression:"'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Uraian Data","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Uraian Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","rows":"4","required":"","name":"deskripsi","placeholder":"Uraian Data"},model:{value:(_vm.dataData.deskripsi),callback:function ($$v) {_vm.$set(_vm.dataData, "deskripsi", $$v)},expression:"dataData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }