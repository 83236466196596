<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Metadata Utama
        </h5>
      </b-col>
      <b-col
        v-if="saktiLoad"
        cols="12"
      >
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span>Memuat data <b>Sakti</b>, mohon menunggu</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="saktiFailed"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found with Sakti
          </h4>
          <div class="alert-body">
            <span>Konektivitas dengan <u>Sakti</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi <u>Admin</u></span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Nama Unit Kerja sesuai dengan Renja'"
          label="Unit Kerja"
          label-for="vi-unit_kerja"
        >
          <validation-provider
            #default="{ errors }"
            name="Unit Kerja"
            rules="required"
          >
            <v-select
              id="vi-unit_kerja"
              v-model="unit_kerja"
              required
              name="unit_kerja"
              :options="saktiUnit"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetKegiatan()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Nama Unit Kerja sesuai dengan Renja'"
          label="Nama Unit Kerja"
          label-for="vi-unit_kerja"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Unit Kerja"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="vi-nama_unit_kerja"
                v-model="s_unit_kerja"
                required
                name="nama_unit_kerja"
                placeholder="Unit Kerja (Lengkap Kode dan Nama)"
                :autofocus="(fokusRo === 'unit') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  v-if="s_unit_kerja"
                  variant="outline-secondary"
                  @click="unitReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Renja'"
          label="Nama Kegiatan"
          label-for="vi-nama_kegiatan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <v-select
              id="vi-nama_kegiatan"
              v-model="nama_kegiatan"
              required
              name="nama_kegiatan"
              :options="saktiKegiatan"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetKro()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Renja'"
          label="Nama Kegiatan"
          label-for="vi-nama_kegiatan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="vi-nama_kegiatan"
                v-model="s_nama_kegiatan"
                required
                name="nama_kegiatan"
                placeholder="Nama Kegiatan (Lengkap Kode dan Nama)"
                :autofocus="(fokusRo === 'giat') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  v-if="s_nama_kegiatan"
                  variant="outline-secondary"
                  @click="namaReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Penanggung Jawab sesuai dengan Renja'"
          label="Nama Penanggung Jawab"
          label-for="vi-pic"
        >
          <b-form-input
            v-model="dataUtama.pic_id"
            hidden
            name="pic_id"
          />
          <validation-provider
            #default="{ errors }"
            name="Nama Penanggung Jawab"
            rules="required"
          >
            <v-select
              id="vi-pic_id"
              v-model="penanggungJawab"
              required
              name="pic_id"
              :options="referenceData.ref_pic"
              label="label"
              placeholder="Pilih Salah Satu atau Tambah Baru"
              @input="setContact()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nomor Kontak Penanggung Jawab sesuai dengan Renja'"
          label="Nomor Penanggung Jawab"
          label-for="vi-pic_number"
        >
          <validation-provider
            #default="{ errors }"
            name="Nomor Penanggung Jawab"
            rules="required"
          >
            <b-form-input
              id="vi-pic_number"
              v-model="dataUtama.pic_number"
              required
              name="pic_number"
              placeholder="Nomor Penanggung Jawab"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Klasifikasi Rincian Output Program sesuai dengan Renja'"
          label="Klasifikasi Rincian Output (KRO)"
          label-for="vi-kode_model_referensi"
        >
          <validation-provider
            #default="{ errors }"
            name="Klasifikasi Rincian Output (KRO)"
            rules="required"
          >
            <v-select
              id="vi-kode_model_referensi"
              v-model="kode_model_referensi"
              required
              name="kode_model_referensi"
              :options="saktiKro"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetRo()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Klasifikasi Rincian Output Program sesuai dengan Renja'"
          label="Klasifikasi Rincian Output (KRO)"
          label-for="vi-kode_model_referensi"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <!-- <v-select
              id="vi-kode_model_referensi"
              ref="kro"
              v-model="kode_model_referensi"
              required
              name="kode_model_referensi"
              :options="saktiKro"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetRo()"
            /> -->
            <b-input-group>
              <b-form-input
                id="vi-kode_model_referensi"
                v-model="s_kode_model_referensi"
                required
                name="kode_model_referensi"
                placeholder="Klasifikasi Rincian Output (KRO) Lengkap Kode dan Nama"
                :autofocus="(fokusRo === 'kro') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  v-if="s_kode_model_referensi"
                  variant="outline-secondary"
                  @click="kroReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-info">Pastikan penulisan KRO telah sesuai dan tepat.<br></small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Rincian Output Program sesuai dengan Renja'"
          label="Rincian Output (RO)"
          label-for="vi-rincian_output"
        >
          <validation-provider
            #default="{ errors }"
            name="Rincian Output (RO)"
            rules="required"
          >
            <v-select
              id="vi-rincian_output"
              v-model="rincian_output"
              required
              name="rincian_output"
              :options="saktiRo"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetItem()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Rincian Output Program sesuai dengan Renja'"
          label="Rincian Output (RO)"
          label-for="vi-rincian_output"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <b-form-input
              id="vi-rincian_output"
              v-model="dataUtama.rincian_output"
              required
              name="rincian_output"
              placeholder="Rincian Output (RO) Lengkap Kode dan Nama"
            />
            <small class="text-info">Pastikan penulisan RO telah sesuai dan tepat.<br></small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Tahun Anggaran Program sesuai dengan Renja'"
          label="Tahun Anggaran"
          label-for="vi-tahun_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Tahun Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-tahun_anggaran"
              v-model="dataUtama.tahun_anggaran"
              required
              name="tahun_anggaran"
              placeholder="Tahun Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(dataUtama.total_anggaran !== '') ? ': Rp. ' + Number(dataUtama.total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="dataUtama.total_anggaran"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(dataUtama.total_anggaran !== '') ? ': Rp. ' + Number(dataUtama.total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="dataUtama.total_anggaran"
              type="number"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Kode Mata Anggaran Program sesuai dengan Renja'"
          label="Kode Mata Anggaran"
          label-for="vi-kode_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Kode Mata Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-kode_anggaran"
              v-model="dataUtama.kode_anggaran"
              required
              name="kode_anggaran"
              placeholder="Kode Mata Anggaran"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Kode Mata Anggaran Program sesuai dengan Renja'"
          label="Kode Mata Anggaran"
          label-for="vi-kode_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Kode Mata Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-kode_anggaran"
              v-model="dataUtama.kode_anggaran"
              required
              name="kode_anggaran"
              placeholder="Kode Mata Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <small class="text-info">contoh : [Kode Dept].[Kode Unit].[Kode Kegiatan].[Kode KRO].[Kode RO]</small>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Apakah Program Merupakan Prioritas Nasional?'"
          label="Prioritas Nasional"
          label-for="vi-nasional_priority"
        >
          <validation-provider
            #default="{ errors }"
            name="Prioritas Nasional"
            rules="required"
          >
            <v-select
              id="vi-nasional_priority"
              v-model="nationalPriority"
              required
              name="nasional_priority"
              :options="daftarNationalPriority"
              placeholder="Pilih Salah Satu"
              label="text"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Apakah Program Merupakan Prioritas Nasional?'"
          label="Prioritas Nasional"
          label-for="vi-prioritas_nasional"
        >
          <validation-provider
            #default="{ errors }"
            name="Prioritas Nasional"
            rules="required"
          >
            <v-select
              id="vi-prioritas_nasional"
              v-model="nationalPriority"
              required
              name="prioritas_nasional"
              :options="daftarNationalPriority"
              placeholder="Pilih Salah Satu"
              label="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Jenis Belanja Program sesuai dengan Renja'"
          label="Jenis Belanja"
          label-for="vi-master_jenis_belanja_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Jenis Belanja"
            rules="required"
          >
            <v-select
              id="vi-master_jenis_belanja_id"
              v-model="master_jenis_belanja_id"
              required
              name="master_jenis_belanja_id"
              :options="referenceData.ref_jenis_belanja"
              placeholder="Pilih Salah Satu"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Jenis Pengadaan Program sesuai dengan Renja'"
          label="Jenis Pengadaan"
          label-for="vi-master_jenis_pengadaan_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Jenis Pengadaan"
            rules="required"
          >
            <v-select
              id="vi-master_jenis_pengadaan_id"
              v-model="master_jenis_pengadaan_id"
              required
              name="master_jenis_pengadaan_id"
              :options="referenceData.ref_jenis_pengadaan"
              placeholder="Pilih Salah Satu"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Sumber Dana Program sesuai dengan Renja'"
          label="Sumber Dana"
          label-for="vi-master_sumber_dana_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sumber Dana"
            rules="required"
          >
            <v-select
              id="vi-master_sumber_dana_id"
              v-model="master_sumber_dana_id"
              required
              name="master_sumber_dana_id"
              :options="referenceData.ref_sumber_dana"
              placeholder="Pilih Salah Satu"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormGroup, VBTooltip, BAlert,
  BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      data_utama_id: '',
      dataUtama: {},
      nationalPriority: '',
      daftarNationalPriority: [
        { value: '0', text: 'Tidak' },
        { value: '1', text: 'Ya' },
      ],
      penanggungJawab: {
        pic_id: '', label: '', pic_number: '',
      },
      daftarKroTambahan: [
        { kdunit: 'other', kdoutput: 'BMA', label: 'BMA. Data dan Informasi Publik' },
        { kdunit: 'other', kdoutput: 'QMA', label: 'QMA. Data dan Informasi Publik' },
        { kdunit: 'other', kdoutput: 'CAN', label: 'CAN. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RAN', label: 'RAN. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'CBT', label: 'CBT. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RBT', label: 'RBT. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'CCL', label: 'CCL. OM Sarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RCL', label: 'RCL. OM Sarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'CDS', label: 'CDS. OM Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RDS', label: 'RDS. OM Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'FAB', label: 'FAB. Sistem Informasi Pemerintahan' },
        { kdunit: 'other', kdoutput: 'UAB', label: 'UAB. Sistem Informasi Pemerintahan' },
      ],
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      nama_kegiatan: '',
      unit_kerja: '',
      s_kode_model_referensi: '',
      s_nama_kegiatan: '',
      s_unit_kerja: '',
      kode_model_referensi: '',
      rincian_output: '',
      useSakti: false,
      fokusRo: false,
      saktiLoad: false,
      saktiFailed: false,
      saktiFailedMsg: '',
      saktiToken: '',
      saktiUnit: [],
      saktiKegiatan: [],
      saktiKro: [],
      saktiRo: [],
      saktiItem: {},
    }
  },
  watch: {
    actionStep() {
      this.saveDataUtama()
    },
  },
  created() {
    this.data_utama_id = this.$route.params.id
  },
  mounted() {
    this.getReference()
    this.splpSaktiGetToken()
  },
  methods: {
    unitReset() {
      this.useSakti = true
      this.unit_kerja = ''
      this.s_unit_kerja = ''
      this.s_nama_kegiatan = ''
      this.s_kode_model_referensi = ''
      this.fokusRo = 'unit'
      this.saktiGetUnit()
    },
    namaReset() {
      this.useSakti = true
      this.s_nama_kegiatan = ''
      this.s_kode_model_referensi = ''
      this.fokusRo = 'giat'
      this.saktiGetKegiatan()
    },
    kroReset() {
      this.useSakti = true
      this.s_kode_model_referensi = ''
      this.fokusRo = 'kro'
      this.saktiGetKegiatan()
    },
    setContact() {
      this.dataUtama.pic = this.penanggungJawab.label
      this.dataUtama.pic_id = this.penanggungJawab.pic_id
      this.dataUtama.pic_number = this.penanggungJawab.pic_number
    },
    splpSaktiGetToken() {
      this.saktiLoad = true
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get(`/sakti/token?token=${localStorage.getItem('userToken')}`)
        .then(res => {
          if (res.data.status === 'success') {
            this.saktiToken = res.data.data.access_token
            this.saktiGetUnit()
          } else {
            this.saktiFailed = true
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.saktiFailedMsg = error
          this.saktiFailed = true
          this.useSakti = false
          this.saktiLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetUnit() {
      this.$splp.get('/ega', {
        headers: {
          Authorization: `Bearer ${this.saktiToken}`,
        },
        params: {
          year: this.dataUtama.tahun_anggaran,
          table: 'unit',
          kddept: this.userData.instansi_kddept,
        },
      })
        .then(res => {
          if (res.data.success === true) {
            this.saktiUnit = []
            const pics = []
            res.data.data.map(value => {
              const pic = value
              pic.label = `${value.kddept}.${value.kdunit}. ${value.nmunit}`
              pics.push(pic)

              if (pic.label === this.dataUtama.unit_kerja) {
                this.unit_kerja = pic
                this.s_unit_kerja = pic.label
                this.useSakti = true
              }
              return true
            })
            if (this.unit_kerja !== '') {
              this.saktiGetKegiatan()
            } else {
              this.saktiUnit = pics
              const lainnya = {
                kdunit: 'other',
                label: 'Unit lainnya',
              }
              this.saktiUnit.push(lainnya)
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            }
          } else {
            this.saktiFailed = true
            this.useSakti = false
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.saktiFailedMsg = error
          this.saktiFailed = true
          this.useSakti = false
          this.saktiLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetKegiatan() {
      if (this.unit_kerja !== null) {
        if (this.unit_kerja !== '' && this.unit_kerja.kdunit !== 'other') {
          this.dataUtama.unit_kerja = this.unit_kerja.label
          this.useSakti = false
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            year: this.dataUtama.tahun_anggaran,
            table: 'giat',
            kddept: this.userData.instansi_kddept,
          }
          if (this.unit_kerja !== '') {
            params.kdunit = this.unit_kerja.kdunit
          }
          this.$splp.get('/ega', {
            headers: {
              Authorization: `Bearer ${this.saktiToken}`,
            },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiKegiatan = []
                this.saktiKro = []
                this.saktiRo = []
                this.nama_kegiatan = ''
                this.kode_model_referensi = ''
                this.rincian_output = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}. ${value.nmgiat}`
                  pics.push(pic)

                  if (pic.label === this.dataUtama.nama_kegiatan) {
                    this.nama_kegiatan = pic
                    this.s_nama_kegiatan = pic.label
                    this.useSakti = true
                  }
                  return true
                })
                if (this.nama_kegiatan !== '') {
                  this.saktiGetKro()
                }
                if (!this.useSakti) {
                  const pics2 = []
                  this.daftarKroTambahan.map(value => {
                    const pic = value
                    pics2.push(pic)

                    if (pic.label === this.dataUtama.kode_model_referensi) {
                      this.kode_model_referensi = pic
                      this.useSakti = false
                    }
                    return true
                  })
                  if (this.kode_model_referensi !== '') {
                    this.saktiGetRo()
                  }
                  this.saktiKro = pics2
                }
                this.saktiKegiatan = pics
                const lainnya = {
                  kdgiat: 'other',
                  label: 'Kegiatan lainnya',
                }
                this.saktiKegiatan.push(lainnya)
                document.getElementById('loading-bg').style.display = 'none'
                this.saktiLoad = false
              } else {
                this.saktiFailed = true
                this.useSakti = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          document.getElementById('loading-bg').style.display = 'none'
          this.useSakti = false
          this.saktiLoad = false
          this.nama_unit_kerja = ''
        }
      } else {
        document.getElementById('loading-bg').style.display = 'none'
        this.saktiGetUnit()
        this.unit_kerja = ''
        this.nama_kegiatan = ''
        this.kode_model_referensi = ''
        this.rincian_output = ''
        this.saktiItem = {}
        this.saktiUnit = []
        this.saktiKegiatan = []
        this.saktiKro = []
        this.saktiRo = []
      }
    },
    saktiGetKro() {
      if (this.nama_kegiatan !== null) {
        if (this.nama_kegiatan !== '' && this.nama_kegiatan.kdgiat !== 'other') {
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            year: this.dataUtama.tahun_anggaran,
            table: 'output',
            kddept: this.userData.instansi_kddept,
            kdunit: this.unit_kerja.kdunit,
          }
          if (this.nama_kegiatan !== '') {
            params.kdgiat = this.nama_kegiatan.kdgiat
          }
          this.$splp.get('/ega', {
            headers: {
              Authorization: `Bearer ${this.saktiToken}`,
            },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.useSakti = true
                this.saktiFailed = false
                this.saktiKro = []
                this.saktiRo = []
                this.kode_model_referensi = ''
                this.rincian_output = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}.${value.kdoutput}. ${value.nmoutput}`
                  pics.push(pic)

                  if (pic.label === this.dataUtama.kode_model_referensi) {
                    this.kode_model_referensi = pic
                  }
                  return true
                })
                if (this.kode_model_referensi !== '') {
                  this.saktiGetRo()
                }
                this.saktiKro = pics
                if (this.s_kode_model_referensi !== this.kode_model_referensi.label) {
                  this.useSakti = false
                  this.saktiLoad = false
                }
                document.getElementById('loading-bg').style.display = 'none'
              } else {
                this.saktiFailed = true
                this.useSakti = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          this.useSakti = false
          this.saktiLoad = false
        }
      } else {
        document.getElementById('loading-bg').style.display = 'none'
        this.nama_kegiatan = ''
        this.kode_model_referensi = ''
        this.rincian_output = ''
        this.saktiItem = {}
        this.saktiKegiatan = []
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetKegiatan()
      }
    },
    saktiGetRo() {
      if (this.kode_model_referensi !== null) {
        if (this.kode_model_referensi !== '' && this.kode_model_referensi.kdunit !== 'other') {
          this.useSakti = true
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            year: this.dataUtama.tahun_anggaran,
            table: 'soutput',
            kddept: this.userData.instansi_kddept,
            kdunit: this.unit_kerja.kdunit,
            kdgiat: this.nama_kegiatan.kdgiat,
          }
          if (this.kode_model_referensi !== '') {
            params.kdoutput = this.kode_model_referensi.kdoutput
          }
          this.$splp.get('/ega', {
            headers: {
              Authorization: `Bearer ${this.saktiToken}`,
            },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiRo = []
                this.rincian_output = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}.${value.kdoutput}.${value.kdsoutput}. ${value.nmsoutput}`
                  pics.push(pic)

                  if (pic.label === this.dataUtama.rincian_output) {
                    this.rincian_output = pic
                  }
                  return true
                })
                if (this.rincian_output !== '') {
                  this.saktiGetItem()
                }
                this.saktiRo = pics
                document.getElementById('loading-bg').style.display = 'none'
                this.saktiLoad = false
              } else {
                this.saktiFailed = true
                this.useSakti = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              document.getElementById('loading-bg').style.display = 'none'
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
            })
        } else {
          this.useSakti = false
          this.saktiLoad = false
          this.fokusRo = 'ro'
          this.rincian_output = ''
          this.total_anggaran = ''
          this.kode_anggaran = ''
          this.prioritas_nasional = ''
        }
      } else {
        document.getElementById('loading-bg').style.display = 'none'
        this.kode_model_referensi = ''
        this.rincian_output = ''
        this.saktiItem = {}
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetKro()
      }
    },
    saktiGetItem() {
      if (this.rincian_output !== null) {
        document.getElementById('loading-bg').style.display = 'block'
        if (this.rincian_output.kdpn !== '0') {
          this.nationalPriority = { value: '1', text: 'Ya' } // Data Prioritas Nasional
        } else {
          this.nationalPriority = { value: '0', text: 'Tidak' } // Data Prioritas Nasional
        }

        const params = {
          year: this.dataUtama.tahun_anggaran,
          table: 'item',
          kddept: this.userData.instansi_kddept,
          kdunit: this.unit_kerja.kdunit,
          kdgiat: this.nama_kegiatan.kdgiat,
          kdoutput: this.kode_model_referensi.kdoutput,
          kdsoutput: this.rincian_output.kdsoutput,
        }
        this.$splp.get('/ega', {
          headers: {
            Authorization: `Bearer ${this.saktiToken}`,
          },
          params,
        })
          .then(res => {
            if (res.data.success === true) {
              this.useSakti = true
              this.saktiFailed = false
              res.data.data.map(value => {
                this.saktiItem = value
                return true
              })
              if (Object.keys(this.saktiItem).length !== 0) {
                this.dataUtama.total_anggaran = this.saktiItem.jmlpagu
                this.dataUtama.kode_anggaran = `${this.saktiItem.kddept}.${this.saktiItem.kdunit}.${this.saktiItem.kdgiat}.${this.saktiItem.kdoutput}.${this.saktiItem.kdsoutput}`
              }
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            } else {
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            }
          })
          .catch(error => {
            document.getElementById('loading-bg').style.display = 'none'
            this.saktiFailedMsg = error
            this.saktiFailed = true
            this.useSakti = false
            this.saktiLoad = false
          })
      } else {
        document.getElementById('loading-bg').style.display = 'none'
        this.rincian_output = ''
        this.saktiItem = {}
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetRo()
      }
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.coreData.tahun_anggaran,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.ref_pic) {
              const pics = []
              res.data.data.ref_pic.map(value => {
                const pic = value
                pic.label = value.nama
                pics.push(pic)
                return true
              })
              this.referenceData.ref_pic = pics
            }
          }
        })
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataUtama = res.data.data

            this.s_unit_kerja = this.dataUtama.unit_kerja
            this.s_nama_kegiatan = this.dataUtama.nama_kegiatan
            this.s_kode_model_referensi = this.dataUtama.kode_model_referensi

            this.penanggungJawab = {
              pic_id: this.dataUtama.pic_id,
              label: this.dataUtama.pic,
              pic_number: this.dataUtama.pic_number,
            }
            this.nationalPriority = this.daftarNationalPriority[this.dataUtama.prioritas_nasional]

            this.master_jenis_belanja_id = {
              jenis_belanja_id: this.dataUtama.jenis_belanja_id,
              nama: this.dataUtama.jenis_belanja,
            }
            this.master_jenis_pengadaan_id = {
              jenis_pengadaan_id: this.dataUtama.jenis_pengadaan_id,
              nama: this.dataUtama.jenis_pengadaan,
            }
            this.master_sumber_dana_id = {
              sumber_dana_id: this.dataUtama.sumber_dana_id,
              nama: this.dataUtama.sumber_dana,
            }
          }
        })
    },
    saveDataUtama() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        instansi_id: this.dataUtama.instansi_id,
        data_utama_id: this.dataUtama.data_utama_id,
        clearance_id: this.dataUtama.clearance_id,
        pic_id: this.dataUtama.pic_id,
        pic_name: this.dataUtama.pic,
        pic_number: this.dataUtama.pic_number,
        kode_model_referensi: this.kode_model_referensi.label,
        tahun_anggaran: this.dataUtama.tahun_anggaran,
        total_anggaran: this.dataUtama.total_anggaran,
        kode_anggaran: this.dataUtama.kode_anggaran,
        prioritas_nasional: this.nationalPriority.value,
        jenis_belanja_id: this.master_jenis_belanja_id.jenis_belanja_id,
        jenis_pengadaan_id: this.master_jenis_pengadaan_id.jenis_pengadaan_id,
        sumber_dana_id: this.master_sumber_dana_id.sumber_dana_id,
      }
      if (this.useSakti) {
        metaUtama.nama_kegiatan = this.nama_kegiatan.label
        metaUtama.unit_kerja = this.unit_kerja.label
        metaUtama.kode_model_referensi = this.kode_model_referensi.label
        metaUtama.rincian_output = this.rincian_output.label
      } else {
        metaUtama.nama_kegiatan = this.s_nama_kegiatan
        metaUtama.unit_kerja = this.s_unit_kerja
        metaUtama.kode_model_referensi = this.s_kode_model_referensi
        metaUtama.rincian_output = this.dataUtama.rincian_output
      }
      this.$http.post('/clearance/core-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          store.commit('app/TOGGLE_SAKTI', this.saktiItem)
          const data = {
            status: true,
            msg: res.data.data,
            sakti: this.saktiItem,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
